<template>
  <div>
    <Button @handleButton="openModal" />
    <!--    <transition name="slide">-->
    <!--      <ModalConstructor v-show="false" @[`close:${modalName}`]="closeModal">-->
    <!--        <template v-for="slot in modal.slots" v-slot:[slot.name]>-->
    <!--          <component-->
    <!--            v-for="item in slot.components"-->
    <!--            :is="item.component"-->
    <!--            :key="item.unique"-->
    <!--            :class="[...item.classes]"-->
    <!--            v-bind="{ ...item.props }"-->
    <!--            v-on="item.event"-->
    <!--          >-->
    <!--          </component>-->
    <!--        </template>-->
    <!--      </ModalConstructor>-->
    <!--    </transition>-->
    <transition name="slide">
      <ModalConstructor v-show="isShowModal" @[`close:${modalName}`]="closeModal">
        <template v-slot:header>
          <InputExample id="text" :value="value" @input="handleInput" @change="handleChange" />
        </template>
        <template v-slot:body></template>
        <template v-slot:footer>
          <Button />
          <Button />
        </template>
      </ModalConstructor>
    </transition>
  </div>
</template>

<script>
import ModalConstructor from "@/helpers/Modal/ModalConstructor.vue";
import Button from "@/modules/UiKit/components/buttons/Button";
import Input from "@/modules/UiKit/components/Inputs/Input";
import InputExample from "@/modules/UiKit/pages/ComponentsPage/Components/ModalPage/InputExample";

export default {
  name: "ConfirmModalPage",
  mixins: [],
  props: {},
  components: { InputExample, Button, ModalConstructor },
  data() {
    return {
      isShowModal: false,
      modal: {},
      modalName: "ModalDefault",
      value: "ModalDefault"
    };
  },
  mounted() {},
  methods: {
    handleInput(event) {},
    handleChange(event) {},
    openModal() {
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}

.slide-enter {
  transform: translate(100%, 0);
}

.slide-leave-to {
  transform: translate(100%, 0);
}
</style>
