<template>
  <div class="input-example">
    <label :for="id" v-if="label.length" v-text="label" />
    <input type="text" v-on="$listeners" v-model="value" v-bind="$attrs" />
  </div>
</template>

<script>
export default {
  name: "InputExample",
  mixins: [],
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ""
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
</style>
