<template>
  <div class="modal-window__wrap" @click.self="closeModal">
    <div class="modal-window">
      <header class="modal-window__header">
        <slot name="header"> Заголовок </slot>
      </header>
      <div class="modal-window__body">
        <slot name="body"> Содержимое модалки </slot>
      </div>
      <footer class="modal-window__footer">
        <slot name="footer">
          <Button type="cancel" title="Отменить" @handleButton="closeModal" />
          <Button title="Сохранить" />
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main";
import Button from "@/modules/UiKit/components/buttons/Button";

export default {
  name: "ModalConstructor",
  mixins: [],
  props: {
    modalName: {
      type: String,
      default: "ModalDefault"
    }
  },
  components: { Button },
  data() {
    return {};
  },
  created() {
    EventBus.$emit(`open:${this.modalName}`);
    this.$emit(`open:${this.modalName}`);
  },
  beforeDestroy() {
    EventBus.$emit(`destroy:${this.modalName}`);
    this.$emit(`destroy:${this.modalName}`);
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit(`close:${this.modalName}`);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.modal-window {
  position: fixed;
  width: 786px;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 1000;
  padding: 24px 24px 40px;
  background: white;
  box-shadow: 3px 0 24px rgba(120, 121, 150, 0.6);
  display: flex;
  flex-flow: column;
  &__wrap {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    z-index: 99;
    position: absolute;
  }
  &__body {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .row-1 {
      flex-basis: 100%;
    }
    .row-2 {
      flex-basis: 48%;
    }
    .block {
      padding-top: 24px;
      padding-bottom: 24px;
    }
    .row-3 {
      flex-basis: 30%;
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: auto;
  }
}
</style>
